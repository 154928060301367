.thienBan {
    grid-area: user;
    border: 1px solid #000;
}

.underline {
    text-align: center;
}

.user-info-title {
    text-align: center;
}

.user-info-title img{
    display: inline-block;
    margin-bottom: 20px;
    width: 280px;
}

.underline span {
    width: 300px;
    height: 1px;
    background: #ccc;
    display: inline-block;
}

.bg img{
    vertical-align: middle;
    border-style: none;
    width: 525px;
    position: absolute;
    top: 51%;
    left: 50%;
    transform: translate(-50%,-50%);
    opacity: 0.1 !important;
    z-index: -1;
    pointer-events: none;
}

.website a{
    text-decoration: none !important;
    font-weight: bold;
    font-size: 16px;
}

.hotline {
    font-weight: bold;
    color: red;
}
