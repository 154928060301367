@font-face {
  font-family: "CustomFont";
  src: url("./font.ttf") format('ttf');
}

@media (min-width: 600px) {
  html {
      font-size: 14px;
  }
}

* {
  font-family: Arial, Helvetica, sans-serif
}

body {
  margin: 0;
  text-align: center;
  background-color: #fff;
}

.container {
  max-width: 54rem;
  /*padding: 0 1rem;*/
}

a {
  color: #1874cd;
  text-decoration: none;
}

a:hover {
  color: #104e8b;
}

h1,
h2,
h3 {
  margin-top: 0;
  margin-bottom: .5rem;
  font-weight: 500;
  line-height: 1.1;
  color: #333;
}

h1 {
  font-size: 3rem;
}

h1 small {
  font-size: 80%;
  color: #777;
}

h2 {
  margin-top: 3rem;
  font-size: 2rem;
}

p {
  margin-top: 0;
}

.container > p {
  margin: .5rem auto 1rem;
  max-width: 800px;
}

hr {
  max-width: 100px;
  margin: 3rem auto;
  border: 0;
  border-top: .1rem solid #eee;
}

/* code {
  padding: .15rem .3rem;
  font-family: Menlo, "Courier New", monospace;
  font-size: 90%;
  color: #cd3333;
  background-color: #f5f5f5;
  border-radius: .15rem;
} */


/* Display utility classes */

.inline-block {
  display: inline-block;
}


/* Masthead */

.masthead {
  padding: 4rem 1rem;
  font-weight: 300;
  color: rgba(255, 255, 255, .65);
  text-align: center;
  /*background-color: #b94a48;*/
}

.masthead a,
.masthead strong {
  font-weight: normal;
  color: #fff;
}

.masthead p {
  font-size: 1.25rem;
}


/* Make it red for easy views */

.grid-example {
  margin-bottom: 1rem;
}

.grid-example .col {
  line-height: 3;
  text-align: center;
  color: #333;
  /*background-color: rgba(255, 0, 0, .15);*/
}


/* Nested grid examples need outdenting because padding. */

.grid-example .grid-example {
  margin-top: .5rem;
  margin-bottom: 0;
}


/* Increase height of vertically centered example */

.grid-example .grid-align-middle {
  height: 200px;
}

.grid-example.grid-align-middle .col {
  min-height: 3rem;
  line-height: 1.5;
}

.container {
  max-width: 940px;
  margin-right: auto;
  margin-left: auto;
}

.laso {
  width: 1082px;
  height: auto;
  /* margin-right: auto;
  margin-left: auto; */
  top: 0;
  left: 0;
  background: #f1f0ec;
  border: 1px solid #000;
  position: absolute;
  transform-origin: top left;
  /* transform: scale(0.5); */
}

.cung-top,
.cung-bottom {
  height: 25px;
  font-size: 18px;
  margin: 2px 2px 0 0;
}

.cung-top {
  font-weight: bold;
  font-size: 18px !important;
}

.cung-middle {
  height: 280px;
}

.cung-middle ul,
li {
  list-style-type: none;
}

.hanhKim {
  color: #9E9E9E;
}

.hanhMoc {
  color: #4CAF50;
}

.hanhThuy {
  color: #000100;
}

.hanhHoa {
  color: #ff0202;
}

.hanhTho {
  color: #e6bd37;
}

.chinhTinh {
  min-height: 35px;
  padding-bottom: 3px;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
}

.cungChu {
  text-transform: uppercase;
  font-size: 18px;
  padding-bottom: 0.2em;
  font-weight: bold;
  color: #2b2d95;
}

.cungThan {
  color: #000 !important;
  font-size: 16px !important;
  text-transform: uppercase;
  font-weight: bold;
}

.xungChieu {
  background-color: #e2d7d491;
}

.diaCung {
  width: 200px;
  height: 240px;
  /*background-color: #f3f6ef;*/
}

.thienBan {
  width: 540px;
  position: relative;
}

.thienBan .noidung {
  padding: 5px 10px 10px 10px;
  text-align: justify;
  font-size: 16px;
}

.thienBan .noidung .header {
  text-align: center;
  font-weight: bold;
  padding-bottom: 1rem;
}

.thienBan .noidung .sinhkhac {
  text-align: center;
  font-weight: bold;
  padding-top: 1rem;
}

.thienBan .noidung .cotTrai {
  text-align: left;
  font-weight: bold;
  line-height: 18px;
}

.thienBan .noidung .cotPhai {
  text-align: left;
  line-height: 1.5em;
  color: #2b2d95;
  font-weight: bold;
}

.thienBan .noidung .grid {
  line-height: 20px;
}

.thienBan .mausac {
  position: absolute;
  height: 50px;
  bottom: 0;
}

.thienBan .mausac .gioithieuhanh {
  /*    width: 50px;*/
  font-weight: bold;
}

input {
  padding: .5em .6em;
  display: inline-block;
  border: 1px solid #ccc;
  box-shadow: inset 0 1px 3px #ddd;
  border-radius: 4px;
  vertical-align: middle;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

form {
  color: #000;
  text-align: left;
}

label {
  padding-right: 2rem;
}

select {
  height: 2.25em;
  border: 1px solid #ccc;
  background-color: #fff;
}

.grid .left {
  width: 30%;
  text-align: right;
}

.grid .right {
  width: 70%;
}

.formborder {
  border: 1px solid #c92424;
  padding: 10px;
}

.border {
  border: thin #a8a8a8 solid;
}

.border-right {
  border-right: thin #a8a8a8 solid;
}

.border-left {
  border-left: thin #a8a8a8 solid;
}

.border-bottom {
  border-bottom: thin #a8a8a8 solid;
}

.border-top {
  border-top: thin #a8a8a8 solid;
}

.inset-border {
  box-sizing: border-box;
}

.button {
  cursor: pointer;
  display: inline-block;
  min-height: 1em;
  outline: 0;
  border: none;
  vertical-align: baseline;
  background: #E0E1E2;
  color: rgba(0, 0, 0, .6);
  font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  margin: 0 .25em 0 0;
  padding: .78571429em 1.5em;
  text-transform: none;
  text-shadow: none;
  font-weight: 700;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  border-radius: .28571429rem;
  box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, .15) inset;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
  transition: opacity .1s ease, background-color .1s ease, color .1s ease, box-shadow .1s ease, background .1s ease;
  will-change: '';
  -webkit-tap-highlight-color: transparent;
}

input.primary:hover {
  background-color: #1678c2;
  color: #FFF;
  text-shadow: none;
}

#namsinh {
  width: 100px;
}

input.warning {
  background-color: #f0ad4e;
  color: #FFF;
  text-shadow: none;
}

input.primary {
  background-color: #2185D0;
  color: #FFF;
  text-shadow: none;
}


/*.phuTinh .col{
  padding-bottom: 0.2em;
}*/

.phuTinh .saotot {
  width: 46%;
  line-height: 1.3em;
  text-align: left;
  display: inline-block;
  white-space: nowrap;
  vertical-align: top;
  max-height: 120px;
  font-weight: bold;
  font-size: 16px;
}

.phuTinh .saoxau {
  width: 53%;
  line-height: 1.3em;
  display: inline-block;
  text-align: right;
  white-space: nowrap;
  vertical-align: top;
  font-weight: bold;
  font-size: 16px;
}

.blocks {
  width: 46%;
  white-space: nowrap;
  display: inline-block;
  /*overflow: hidden;
  text-overflow: ellipsis;*/
  line-height: 1.2em;
  font-size: 14px;
}


/*.blocks:nth-child(odd){
  text-align: left;
}
.blocks:nth-child(even){
  text-align: right;
}*/

.anlaso {
  display: none !important;
}

.hide {
  width: 46%;
  white-space: nowrap;
  display: inline-block;
  line-height: 1.2em;
  font-size: 14px;
  height: 0;
  overflow: hidden;
}


/*@media (min-width: 600px) {*/

.grid {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.col {
  display: table-cell;
  padding: 0 !important;
}

.thongtin {
  padding-bottom: 1rem;
}

.grid-padded {
  margin-left: -1rem;
  margin-right: -1rem;
}


/*.grid-padded .grid {
  border-spacing: 1rem 0;
}*/

.label {
  display: inline;
  padding: .2em .6em .3em;
  font-size: 90%;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25em;
}


.label-triet {
  background-color: #000;
  padding-left: 8px;
  padding-right: 8px;
  display: inline-block;
  text-transform: uppercase;
  font-size: 16px;
}



/*}*/


/*@media (min-width: 600px) {*/

.col-1 {
  width: 8.333333%;
}

.col-2 {
  width: 16.666667%;
}

.col-3 {
  width: 25%;
}

.col-4 {
  width: 33.333333%;
}

.col-5 {
  width: 41.666667%;
}

.col-6 {
  width: 50%;
}

.col-7 {
  width: 58.333333%;
}

.col-8 {
  width: 66.666667%;
}

.col-9 {
  width: 75%;
}

.col-10 {
  width: 83.333333%;
}

.col-11 {
  width: 91.666667%;
}

.grid-align-middle .col {
  vertical-align: middle;
}

.grid-reverse {
  direction: rtl;
}

.grid-reverse .col {
  direction: ltr;
}

.Zebra_Tooltip {
  position: absolute;
  z-index: 8000;
  background: transparent;
}

.Zebra_Tooltip .Zebra_Tooltip_Message {
  position: relative;
  padding: 15px;
  font-size: inherit;
  text-align: left;
  font-weight: normal;
  line-height: inherit;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  -webkit-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
  -moz-box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.6);
  *margin-right: 0;
}

.Zebra_Tooltip .Zebra_Tooltip_Arrow {
  position: absolute;
  height: 10px;
  width: 20px;
  overflow: hidden
}

.Zebra_Tooltip .Zebra_Tooltip_Arrow_Bottom {
  bottom: 0
}

.Zebra_Tooltip .Zebra_Tooltip_Arrow_Top {
  top: 0
}

.Zebra_Tooltip .Zebra_Tooltip_Arrow div {
  position: absolute;
  border-style: solid;
  border-width: 10px;
  height: 0;
  width: 0;
  left: 0;
  _border-left-color: pink;
  _border-right-color: pink;
  _filter: chroma(color=pink);
}

.Zebra_Tooltip .Zebra_Tooltip_Arrow_Bottom div {
  top: 0;
  _border-bottom-color: pink;
}

.Zebra_Tooltip .Zebra_Tooltip_Arrow_Top div {
  bottom: 0;
  _border-top-color: pink;
}

.Zebra_Tooltip .Zebra_Tooltip_Close {
  text-decoration: none;
  color: #FFF;
  position: absolute;
  right: 8px;
  top: 1px;
  font-size: inherit;
  font-weight: bold;
}

.Zebra_Tooltip .Zebra_Tooltip_Close:hover {
  color: #C40000;
}

.tooltips {
  cursor: help;
  white-space: nowrap;
  font-size: 15px;
}

/* .thien-di, .no-boc {
  color: #ff0202;
}

.tat-ach, .phu-the, .phu-mau, .quan-loc {
  color: #ffa102;
}

.tai-bach, .tu-tuc {
  color: #b6b6b6;
}

.huynh-de, .menh {
  color: #000100;
}

.phuc-duc, .dien-trach {
  color: #019900;
} */
