.search-input {
    border: 1px solid #ccc;
    border-radius: 4px;
}
@media screen and (max-width: 600px) {
    .search-input {
        width: 100% !important;
        overflow: hidden;
    }
}
.submit {
    margin-left: 150px;
}