.horoscope-item{
    width: 270px;
    border: 1px solid #000;
    padding: 8px;
    cursor: pointer;
    position: relative;
}

.ti{
    grid-area: ti;
}

.suu{
    grid-area: suu;
}

.dan {
    grid-area: dan;
}

.mao {
    grid-area: mao;
}

.thil {
    grid-area: thil;
}

.ty {
    grid-area: ti;
}

.ngo {
    grid-area: ngo;
}

.mui {
    grid-area: mui;
}

.than {
    grid-area: than;
}

.dau {
    grid-area: dau;
}

.tuot {
    grid-area: tuot;
}

.hoi {
    grid-area: hoi;
}

.top-center-cung {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.bottom-right-cung {
    position: absolute;
    bottom: 0;
    right: 0;
    transform: translate(50%, 50%);
    z-index: 10;
}

.bottom-center-cung {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 50%);
    z-index: 10;
}

.bottom-left-cung {
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translate(-50%,50%);
    z-index: 10;
}
